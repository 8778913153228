import store from "../../store";
import {
	ADMIN_UNSET_TOKEN,
	CORE_SET_PAGE,
	CORE_UNSET_TOKEN,
	USER_UNSET,
} from "../types";

export const handleReturnedError = (err, showAlert = true) => {
	if (err.response.status === 419) {
		store.dispatch({ type: CORE_SET_PAGE, payload: "home" });
		store.dispatch({ type: CORE_UNSET_TOKEN, payload: null });
		store.dispatch({ type: USER_UNSET, payload: null });
		return;
	}
	console.log(err);
	if (showAlert) {
		alert(err.response ? err.response.data : err.message);
	}
};

export const handleAdminReturnedError = (err, showAlert = true) => {
	if (err.response && err.response.status === 419) {
		store.dispatch({ type: ADMIN_UNSET_TOKEN, payload: null });
		return;
	}
	console.log(err);
	if (showAlert) {
		alert(err.response ? err.response.data : err.message);
	}
};
