import { useSelector } from "react-redux";
import { setPage } from "../../redux/actions/coreActions";

import {
	Assignment,
	DirectionsCarOutlined,
	HomeOutlined,
	Menu,
	QuestionAnswer,
} from "@material-ui/icons";
import { Fragment, useState } from "react";
import { setCurrentQuiz } from "../../redux/actions/coreActions";
import {
	LogoutIcon,
	MiniInfoIcon,
	MiniPaperclipIcon,
	MiniPersonIcon,
	TickIcon,
	TrophyIcon,
} from "../ExtraIcons";
import ActionButton from "../ActionButton";
import "./HomeNavbar.css";

const HomeNavbar = (props) => {
	const user = useSelector((state) => state.user);
	const quiz = useSelector((state) => state.quiz);
	const rotation = useSelector((state) => state.user.rotation);

	const [showMenu, setShowMenu] = useState(false);

	return (
		<Fragment>
			<div className="navbarMenu">
				<div className={`menuGrid`}>
					<div className="menuGridItem">
						<ActionButton
							title="MENU"
							id="btnMenu"
							action={() => setShowMenu(!showMenu)}
							icon={<Menu />}
						/>
					</div>
				</div>
			</div>
			<div className={`homeNavbar ${showMenu ? "navVisible" : "navHidden"}`}>
				<div className={`menuGrid`}>
					<div
						className="menuGridItem"
						id={props.page === "home" ? "btnSignOut" : "btnHome"}
					>
						<ActionButton
							title={props.page === "home" ? "SIGN OUT" : "HOME"}
							action={() => {
								props.onHomeSignOut();
								setShowMenu(false);
							}}
							icon={props.page === "home" ? <LogoutIcon /> : <HomeOutlined />}
						/>
					</div>
					<div className="menuGridItem" id="btnDetails">
						<ActionButton
							title="MY DETAILS"
							action={() => {
								setPage("mydetails");
								setShowMenu(false);
							}}
							icon={<MiniPersonIcon />}
							status={user.detailsComplete ? <TickIcon /> : null}
						/>
					</div>
					<div className="menuGridItem" id="btnDisclaimer">
						<ActionButton
							title="INDEMNITY FORM"
							action={() => {
								setPage("disclaimer");
								setShowMenu(false);
							}}
							icon={<MiniPaperclipIcon />}
							status={user.indemnityComplete ? <TickIcon /> : null}
						/>
					</div>
					<div className="menuGridItem" id="btnDVLA">
						<ActionButton
							title="DVLA CHECK"
							action={() => {
								setPage("dvla");
								setShowMenu(false);
							}}
							//disabled={!user.dvlaOpen}
							icon={<DirectionsCarOutlined />}
							status={user.dvlaComplete ? <TickIcon /> : null}
						/>
					</div>
					<div className={`menuGridItem`} id="btnQuiz">
						<ActionButton
							title="QUIZ"
							action={() => {
								//setPage("quiz");
								setCurrentQuiz(1);
								setShowMenu(false);
							}}
							icon={<TrophyIcon />}
							status={
								quiz.quizList &&
								quiz.quizList[1] &&
								quiz.quizList[1].complete ? (
									<TickIcon />
								) : null
							}
						/>
					</div>

					{/* <div className="menuGridItem" id="btnTrainers">
						<ActionButton
							title="MEET THE TEAM"
							action={() => {
								setPage("trainers");
								setShowMenu(false);
							}}
							icon={<MiniTeamIcon />}
						/>
					</div> */}

					<div className="menuGridItem" id="btnEventInfo">
						<ActionButton
							title="EVENT INFORMATION"
							action={() => {
								setPage("eventInfo");
								setShowMenu(false);
							}}
							icon={<MiniInfoIcon />}
						/>
					</div>
					{user.kcOpen ? (
						<div className={`menuGridItem`} id="btnKnowledgeChecks">
							<ActionButton
								title="SESSION EVALUATIONS"
								action={() => {
									setPage("quizzes");
									setShowMenu(false);
								}}
								icon={<Assignment />}
								status={null}
							/>
						</div>
					) : null}
					<div className="menuGridItem" id="btnGenius">
						<ActionButton
							title="ANY QUESTIONS"
							action={() => {
								setPage("geniusbar");
								setShowMenu(false);
							}}
							icon={<QuestionAnswer />}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default HomeNavbar;
