import { Typography } from "@material-ui/core";

const ActionButton = (props) => {
	return (
		<div
			id={props.id}
			className={`actionButton ${props.disabled ? "disabled" : ""} ${
				props.className
			}`}
			onClick={props.action}
		>
			{props.icon || props.status ? (
				<div className="actionButtonIconContainer">
					{props.icon ? (
						<div className="actionButtonIcon">{props.icon}</div>
					) : null}
					{props.status ? (
						<div className="actionButtonStatus">{props.status}</div>
					) : null}
				</div>
			) : (
				<div className="actionButtonImg" />
			)}

			<Typography className="actionButtonTitle">{props.title}</Typography>
		</div>
	);
};

export default ActionButton;
